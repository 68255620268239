import firebase from "../lib/firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

/* This file will hold our exported database references. */

export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;

export const auth = firebase.auth;

export const db = firebase.firestore();

export const deleteField = firebase.firestore.FieldValue.delete;

export const couponsRef = db.collection("Coupons");

export const eventsRef = db.collection("Events");

export const groupsRef = db.collection("Groups");

export const increment = firebase.firestore.FieldValue.increment;

export const plansRef = db.collection("Plans");

export const storageRef = firebase.storage();

export const timestamp = firebase.firestore.FieldValue.serverTimestamp();

export const usersRef = db.collection("Users");
