import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./Components/AuthGuard";
import DashboardLayout from "./Components/dashboard/DashboardLayout";
import DocsLayout from "./Components/docs/DocsLayout";
import GuestGuard from "./Components/GuestGuard";
import LoadingScreen from "./Components/LoadingScreen";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import("./Screens/authentication/Login")));
const InvitationCode = Loadable(
  lazy(() => import("./Screens/authentication/InvitationCode"))
);
const PasswordRecovery = Loadable(
  lazy(() => import("./Screens/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./Screens/authentication/PasswordReset"))
);
const PasswordUpdate = Loadable(
  lazy(() => import("./Screens/authentication/PasswordUpdate"))
);
const Register = Loadable(
  lazy(() => import("./Screens/authentication/Register"))
);

// Dashboard pages
const Account = Loadable(lazy(() => import("./Screens/dashboard/Account")));
// const Calendar = Loadable(lazy(() => import("./Screens/dashboard/Calendar")));
// const Chat = Loadable(lazy(() => import("./Screens/dashboard/Chat")));
const Overview = Loadable(lazy(() => import("./Screens/dashboard/Overview")));

// Docs pages
const Docs = Loadable(lazy(() => import("./Screens/Docs")));

// Error pages
const AuthorizationRequired = Loadable(
  lazy(() => import("./Screens/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./Screens/NotFound")));
const ServerError = Loadable(lazy(() => import("./Screens/ServerError")));

// Other pages
const Contact = Loadable(lazy(() => import("./Screens/Contact")));
// const Home = Loadable(lazy(() => import("./Screens/Home")));
const More = Loadable(lazy(() => import("./Screens/More/More")));

// Loading redirect based on current auth position.
const Loading = Loadable(lazy(() => import("./Screens/Misc/Loading")));

// Stuff for slots.
const SlotManagement = Loadable(
  lazy(() => import("./Screens/Slots/ManageSlots"))
);
const SlotMemberProfile = Loadable(
  lazy(() => import("./Screens/Slots/MemberProfile"))
);

// Groups
const GroupProfile = Loadable(
  lazy(() => import("./Screens/Groups/GroupProfile"))
);

// User Management stuff.
const UserManagement = Loadable(
  lazy(() => import("./Screens/Users/Management"))
);
const MigrationManagement = Loadable(
  lazy(() => import("./Screens/Migration/Management"))
);
const GroupManagement = Loadable(
  lazy(() => import("./Screens/Groups/Management"))
);

// Coupon/discount code management
const CouponManagement = Loadable(
  lazy(() => import("./Screens/Coupons/Management"))
);

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "invitation-code",
        element: (
          <GuestGuard>
            <InvitationCode />
          </GuestGuard>
        ),
      },
      {
        path: "password-update",
        element: (
          <AuthGuard>
            <PasswordUpdate />
          </AuthGuard>
        ),
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
    ],
  },
  {
    path: "contact",
    element: <Contact />,
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/", element: <Overview /> },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "more",
        children: [
          {
            path: "/",
            element: <More />,
          },
        ],
      },
      {
        path: "slots",
        children: [
          {
            path: "/",
            element: <SlotManagement />,
          },
          { path: "/profile", element: <SlotMemberProfile /> },
        ],
      },
      {
        path: "users",
        children: [
          {
            path: "/",
            element: <UserManagement />,
          },
        ],
      },
      {
        path: "groups",
        children: [
          {
            path: "/",
            element: <GroupManagement />,
          },
          { path: "/profile", element: <GroupProfile /> },
        ],
      },
      {
        path: "migration",
        children: [
          {
            path: "/",
            element: <MigrationManagement />,
          },
        ],
      },
      {
        path: "coupons",
        children: [
          {
            path: "/",
            element: <CouponManagement />,
          },
        ],
      },
    ],
  },
  {
    path: "docs",
    element: <DocsLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: "*",
        element: <Docs />,
      },
    ],
  },
  {
    path: "*",
    element: <Loading />,
    children: [
      {
        path: "/",
        element: <Loading />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
