import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  // Divider,
  TextField,
  // Typography,
} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
import { useNavigate } from "react-router";

/* Formik form will handle the submit call in the onsubmit callback. */
const LoginFirebase = (props) => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const { signInUserCheckWithEmailAndPassword } = useAuth();

  // const handleGoogleClick = async () => {
  //   try {
  //     await signInWithGoogle();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <div {...props}>
      {/* <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        sx={{
          backgroundColor: "common.white",
          color: "common.black",
          "&:hover": {
            backgroundColor: "common.white",
            color: "common.black",
          },
        }}
        variant="contained"
      >
        <Box
          alt="Google"
          component="img"
          src="/static/icons/google.svg"
          sx={{ mr: 1 }}
        />
        Google
      </Button>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          mt: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
        <Typography color="textSecondary" sx={{ m: 2 }} variant="body1">
          OR
        </Typography>
        <Box sx={{ flexGrow: 1 }}>
          <Divider orientation="horizontal" />
        </Box>
      </Box> */}
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const res = await signInUserCheckWithEmailAndPassword(
              values.email,
              values.password
            );

            if (res.message) {
              throw res;
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }

            res.role === "Admin"
              ? navigate("/dashboard/users")
              : navigate("/dashboard");
          } catch (err) {
            console.error("CAUGHT ERROR:", err);
            let message = err.message;

            if (
              err.code === "auth/wrong-password" ||
              err.code === "auth/user-not-found"
            ) {
              message = "Your email or password is incorrect.";
            }

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              {errors.submit && (
                <Alert severity="error">
                  <div>{errors.submit}</div>
                </Alert>
              )}
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginFirebase;
