import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import firebase from "../lib/firebase";
import { checkIfSlottedMember, getUserData } from "../Helpers/User";

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: "Firebase",
  createUserWithEmailAndPassword: () => Promise.resolve(),
  currentUser: null,
  sendPasswordResetEmail: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInUserCheckWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserProfile: () => Promise.resolve(),
  updateCachedUser: null,
  claimAccountWithCode: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          // Get the user's data
          let { name, role, migration, legacy } = await getUserData(user.uid);
          let slottedMember = await checkIfSlottedMember(user.uid);
          slottedMember =
            typeof slottedMember === "boolean" ? slottedMember : false;
          if (
            !role ||
            (role !== "Admin" && role !== "Group Owner" && role !== "User")
          ) {
            role = "User";
          }

          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                // avatar: user.photoURL,
                email: user.email,
                isSlotted: slottedMember,
                name,
                phone: user.phoneNumber,
                plan: "Premium",
                role,
                migration, // May be undefined
                legacy, // May be undefined
              },
            },
          });
        } else {
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch]
  );

  const createUserWithEmailAndPassword = async (email, name, password) => {
    return await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        await firebase.auth().currentUser.updateProfile({ displayName: name });
        return user;
      });
  };

  const currentUser = async () => {
    return firebase.auth().currentUser;
  };

  const logout = async () => {
    return await firebase
      .auth()
      .signOut()
      .then((res) => {
        return res;
      });
  };

  const sendPasswordResetEmail = async (email) => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  const signInWithEmailAndPassword = async (email, password) => {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInUserCheckWithEmailAndPassword = async (email, password) => {
    try {
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);

      return await getUserData(user.uid);
    } catch (error) {
      console.error("ERROR:", error);
      return error;
    }
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const updateUserProfile = async (name) => {
    return await firebase
      .auth()
      .currentUser.updateProfile({ displayName: name });
  };

  const updateCachedUser = (user, role, slottedMember) => {
    dispatch({
      type: "AUTH_STATE_CHANGED",
      payload: {
        isAuthenticated: true,
        user: {
          id: user.uid ?? user.id,
          // avatar: user.photoURL,
          email: user.email,
          isSlotted: slottedMember,
          name: user.displayName ?? user.name,
          phone: user.phoneNumber ?? user.phone,
          plan: "Premium",
          role,
        },
      },
    });
  };

  const claimAccountWithCode = async (email, code) => {
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          email,
          code,
        }),
      };

      const res = await fetch(process.env.REACT_APP_CLAIM_ACCOUNT_URL, options);

      const data = await res.json();
      return data;
    } catch (error) {
      console.log("Failed to claim user account: ", error);
      return error;
    }
  };

  const updatePassword = async (password) => {
    try {
      const user = firebase.auth().currentUser;
      await user.updatePassword(password);

      return {
        success: true,
      };
    } catch (error) {
      console.log("Failed to update user password: ", error);
      return error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Firebase",
        createUserWithEmailAndPassword,
        currentUser,
        sendPasswordResetEmail,
        signInWithEmailAndPassword,
        signInUserCheckWithEmailAndPassword,
        signInWithGoogle,
        logout,
        updateUserProfile,
        updateCachedUser,
        claimAccountWithCode,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
