import {
  arrayUnion,
  auth,
  groupsRef,
  increment,
  plansRef,
  usersRef,
} from "../Api/Firebase";
import { getQueryParams } from "./Parsers";

/* Add an authenticated user to a group. */
export async function addUserToGroup(userId, groupId) {
  try {
    const _groupId = groupId ? groupId : getQueryParams().groupId;

    // Get the amount of slots the group has.
    const groupDoc = await groupsRef.doc(_groupId).get();
    const slotsAllowed = groupDoc.data().slotsAllowed;
    const slotsUsed = groupDoc.data().slotsUsed;

    if (slotsUsed >= slotsAllowed) {
      return "Group is full.";
    }

    // Update the user's info.
    await usersRef.doc(userId).update({
      _groupId,
    });

    // Add the user to the group.
    return await groupsRef
      .doc(_groupId)
      .update({
        members: arrayUnion(userId),
        slotsUsed: increment(1),
      })
      .then(() => {
        return "SUCCESS";
      });
  } catch (error) {
    console.error("ERROR:", error);
    return error;
  }
}

/* Create a new user in Firestore. Returns a success or error message. */
export async function createUser(name, { email, uid }) {
  return await usersRef
    .doc(uid)
    .set({
      _id: uid,
      email,
      name,
      role: "User",
      showInitialAppInfo: true,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch(({ message }) => {
      return message;
    });
}

/* Get the firestore data of one user. Returns the user document or error message. */
export async function getUserData(_id) {
  const doc = await usersRef
    .doc(_id)
    .get()
    .catch((error) => {
      return error.message;
    });

  if (doc.exists) {
    return doc.data();
  }

  return { message: "No User Found" };
}

/* Get the firestore group data by ID. Returns the group document or undefined. */
export async function getGroupData(_groupId) {
  try {
    if (!_groupId) {
      throw new Error("Group ID missing.");
    }

    const groupDoc = await groupsRef.doc(_groupId).get();

    if (!groupDoc.exists) {
      throw new Error("No group data found.");
    }

    const groupData = groupDoc.data();

    return groupData;
  } catch (error) {
    return;
  }
}

/* Update a user's data in firestore from the profile screen. */
export async function updateFirestoreUser(userObj, { name, phone }) {
  try {
    await auth().currentUser.updateProfile({ displayName: name });
    await usersRef.doc(userObj.id).update({
      name,
      phone,
    });

    return "SUCCESS";
  } catch (error) {
    return error;
  }
}

/* Update a user's password (they will need to be logged in recently). Returns a success or error message.*/
export async function updateUserPassword(password) {
  return await auth()
    .currentUser.updatePassword(password)
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Update the user's profile image. */
export async function updateUserAvatar(imageName, avatar, uid) {
  await auth().currentUser.updateProfile({ photoURL: avatar });

  return await usersRef
    .doc(uid)
    .update({
      avatar,
      imageName,
    })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error;
    });
}

/* Update the user's role. */
export async function updateUserRole(userId, role) {
  return await usersRef
    .doc(userId)
    .update({ role })
    .then(() => {
      return "SUCCESS";
    })
    .catch((error) => {
      return error.message;
    });
}

/* Get a user's stripe plan info. Pass the stripePlanId. */
export async function getUserStripeInfo(planId) {
  const doc = await plansRef
    .doc(planId)
    .get()
    .catch(({ message }) => {
      return message;
    });

  if (doc.exists) {
    return doc.data();
  }

  return { message: "No Plan Found" };
}

/* Get all subscriptions from firestore. */
export async function getAllSubscriptions(comparator) {
  return await plansRef
    .where("hidden", "==", false)
    .get()
    .then((querySnapshot) => {
      let temp = [];
      querySnapshot.forEach((doc) => {
        // Adding IF conditional to avoid indexing
        if (!comparator) {
          temp.push(doc.data());
        } else if (
          !doc.data().planType ||
          (doc.data().planType &&
            doc.data().planType.toLowerCase() !== comparator)
        ) {
          temp.push(doc.data());
        }
      });
      return temp;
    });
}

/* Get all users in our users collection. */
export async function getUsers() {
  let temp = [];
  const querySnapshot = await usersRef.get();
  querySnapshot.forEach((doc) => {
    temp.push(doc.data());
  });
  return temp;
}

/* Get all users and their subscription info if applicable. */
export async function getUsersAndSubscription() {
  const temp = [];
  const users = await getUsers();
  for (const user of users) {
    if (user.subscriptions) {
      const subscriptionId = user.subscriptions[0]._planId;
      if (user.subscriptions[1]) {
        const additionalPlanId = user.subscriptions[1]._planId;
        const additionalPlan = await plansRef.doc(additionalPlanId).get();
        user.additionalPlan = additionalPlan.data();
      }
      const plan = await plansRef.doc(subscriptionId).get();
      user.plan = plan.data();
      temp.push(user);
    }
  }

  return temp;
}

/* Get a user's subscription info */
export async function getUserSubscription(subscriptions) {
  if (!subscriptions) {
    return;
  }

  let plan, additionalPlan;
  const subscriptionId = subscriptions[0]._planId;
  if (subscriptions[1]) {
    const additionalPlanId = subscriptions[1]._planId;
    const additionalPlanDoc = await plansRef.doc(additionalPlanId).get();
    additionalPlan = additionalPlanDoc.data();
  }
  const planDoc = await plansRef.doc(subscriptionId).get();
  plan = planDoc.data();

  return {
    plan,
    additionalPlan,
  };
}

/* Check if the user is a part of a slot. */
/* Basically verifies that this user belongs to a group and is listed as a member. */
export async function checkIfSlottedMember(userId) {
  try {
    let userDoc = await usersRef
      .doc(userId)
      .get()
      .catch(({ message }) => {
        return message;
      });

    userDoc = userDoc.data();

    if (userDoc._groupId) {
      let groupDoc = await groupsRef
        .doc(userDoc._groupId)
        .get()
        .catch(({ message }) => {
          return message;
        });

      groupDoc = groupDoc.data();

      if (groupDoc.members.indexOf(userId) !== -1) {
        return true;
      }
    }

    return false;
  } catch (error) {
    return error.message;
  }
}

/* Get the subscribed user's current subscription. */
export async function getCurrentSubscription(userId) {
  let userDoc = await usersRef.doc(userId).get();
  userDoc = userDoc.data();

  const subscriptions = userDoc.subscriptions;
  const planId = subscriptions[0]._planId;

  let planDoc = await plansRef.doc(planId).get();

  let plan = planDoc.data();
  plan.last4 = subscriptions[0].last4;

  return plan;
}

/* Get secondary subscription */
export async function getSecondarySubscription(userId) {
  let userDoc = await usersRef.doc(userId).get();
  userDoc = userDoc.data();

  const subscriptions = userDoc.subscriptions;
  if (subscriptions[1]) {
    const planId = subscriptions[1]._planId;
    const planDoc = await plansRef.doc(planId).get();
    const plan = planDoc.data();

    return { additionalSlots: subscriptions[1].maxSlots, amount: plan.amount };
  } else {
    return null;
  }
}

/* Look up if a user exists by their email. */
export async function getUserByEmail(email) {
  try {
    let userData = await usersRef.where("email", "==", email).get();
    return !userData.empty ? [userData.docs[0].data()] : [];
  } catch (error) {
    console.error("USER LOOKUP ERROR:", error);
    return [];
  }
}
