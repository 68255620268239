import { Box, Container } from "@material-ui/core";

// const platformIcons = {
//   Amplify: "/static/icons/amplify.svg",
//   Auth0: "/static/icons/auth0.svg",
//   Firebase: "/static/icons/firebase.svg",
//   JWT: "/static/icons/jwt.svg",
// };

const AuthBanner = () => (
  <Box
    sx={{
      backgroundColor: "background.paper",
      borderBottom: 1,
      borderColor: "divider",
      py: 2,
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <Chip color="primary" label="NEW" size="small" />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            ml: 2,
            "& > img": {
              height: 30,
              mx: 2,
            },
          }}
        >
          <Tooltip title="Firebase">
            <img alt="Firebase" src={platformIcons.Firebase} />
          </Tooltip>
        </Box> */}
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
