import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Link,
  Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import CalendarIcon from "../../icons/Calendar";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import InfoIcon from "../../icons/InformationCircle";
import UserIcon from "../../icons/User";
import UsersIcon from "../../icons/Users";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { getUserData, getUserStripeInfo } from "../../Helpers/User";
import ContactModal from "./ContactModal";

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [show, setShow] = useState(false);
  const [userPlan, setUserPlan] = useState({});

  const generalArray = [];
  const customersArray = [];
  const appsArray = [];

  if (user.role !== "Admin") {
    generalArray.push({
      title: "Overview",
      path: "/dashboard",
      icon: <ChartSquareBarIcon fontSize="small" />,
    });
  }

  generalArray.push({
    title: "Account",
    path: "/dashboard/account",
    icon: <UserIcon fontSize="small" />,
  });

  if (user.role === "Admin") {
    customersArray.push({ title: "Users", path: "/dashboard/users" });
    customersArray.push({ title: "Groups", path: "/dashboard/groups" });
    customersArray.push({ title: "Coupons", path: "/dashboard/coupons" });
    customersArray.push({ title: "Migration", path: "/dashboard/migration" });
  }

  if (user.role !== "Admin" && user.isSlotted) {
    customersArray.push({ title: "Slots", path: "/dashboard/slots" });
    appsArray.push({
      title: "Calendar",
      path: "/dashboard/calendar",
      icon: <CalendarIcon fontSize="small" />,
    });
  }

  const sections = [
    {
      title: "General",
      items: generalArray,
    },
    {
      title: "More",
      items: [
        {
          title: "Info",
          path: "/dashboard/more",
          icon: <InfoIcon fontSize="small" />,
        },
      ],
    },
  ];

  if (user.role === "Admin") {
    sections.splice(1, 0, {
      title: "Management",
      items: [
        {
          title: "Customers",
          path: "/dashboard/customers",
          icon: <UsersIcon fontSize="small" />,
          children: customersArray,
        },
      ],
    });
  } else if (user.role === "Group Owner") {
    sections.splice(1, 0, {
      title: "Management",
      items: [
        {
          title: "My Members",
          path: "/dashboard/slots",
          icon: <UsersIcon fontSize="small" />,
        },
      ],
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // Disabling Eslint error because sidebar does not work with openMobile and onMobileClose being added.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /* When the screen loads, get the user's data once. */
  useEffect(() => {
    const callGetUserData = async (userId) => {
      const data = await getUserData(userId);

      if (data && data.message !== "No User Found") {
        if (data.subscriptions) {
          const plan = await getUserStripeInfo(data.subscriptions[0]._planId);
          setUserPlan(plan);
        }
      }
    };

    callGetUserData(user.id);
  }, [user.id]);

  /* Toggle the contact modal from being shown. */
  const toggleModal = () => {
    setShow(!show);
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            {/* Uncomment to show avatar. */}
            {/* <RouterLink to="/dashboard/account">
              <Avatar
                src={avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink> */}
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.name}
              </Typography>
              {userPlan && userPlan.title && (
                <Typography color="textSecondary" variant="body2">
                  Your plan:{" "}
                  <Link color="primary" component={RouterLink} to="/pricing">
                    {userPlan.title}
                  </Link>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            Need Help?
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Contact an Admin Here
          </Typography>
          <Button
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={toggleModal}
          >
            Contact
          </Button>
        </Box>
        {show && <ContactModal showDialog={show} toggleDialog={toggleModal} />}
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
