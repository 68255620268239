import { createSlice } from "@reduxjs/toolkit";
import {
  getEvents,
  createEvent,
  updateEvent,
  deleteEvent,
} from "../Helpers/Calendar";

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    callGetEvents(state, action) {
      state.events = action.payload;
    },
    callCreateEvent(state, action) {
      state.events.push(action.payload);
    },
    selectEvent(state, action) {
      state.isModalOpen = true;
      state.selectedEventId = action.payload;
    },
    callUpdateEvent(state, action) {
      const event = action.payload;

      state.events = state.events.map((_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    callDeleteEvent(state, action) {
      state.events = state.events.filter(
        (event) => event.id !== action.payload
      );
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end,
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
  },
});

export const { reducer } = slice;

export const callGetEvents = () => async (dispatch) => {
  const data = await getEvents();
  dispatch(slice.actions.callGetEvents(data));
};

export const callCreateEvent = (createData) => async (dispatch) => {
  const data = await createEvent(createData);

  dispatch(slice.actions.callCreateEvent(data));
};

export const selectEvent = (eventId) => async (dispatch) => {
  dispatch(slice.actions.selectEvent(eventId));
};

export const callUpdateEvent = (eventId, update) => async (dispatch) => {
  const data = await updateEvent({
    eventId,
    update,
  });

  dispatch(slice.actions.callUpdateEvent(data));
};

export const callDeleteEvent = (eventId) => async (dispatch) => {
  await deleteEvent(eventId);

  dispatch(slice.actions.callDeleteEvent(eventId));
};

export const selectRange = (start, end) => (dispatch) => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = () => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export default slice;
