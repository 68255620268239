import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import AuthBanner from "../../Components/authentication/AuthBanner";
import { LoginFirebase } from "../../Components/authentication/login";
import Logo from "../../Components/Logo";
// import useAuth from "../../hooks/useAuth";
// import gtm from "../../lib/gtm";

// const platformIcons = {
//   Amplify: "/static/icons/amplify.svg",
//   Auth0: "/static/icons/auth0.svg",
//   Firebase: "/static/icons/firebase.svg",
//   JWT: "/static/icons/jwt.svg",
// };

const Login = () => {
  // const { platform } = useAuth();

  // useEffect(() => {
  //   gtm.push({ event: "page_view" });
  // }, []);

  return (
    <>
      <Helmet>
        <title>ITEA | Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <AuthBanner />
        <Container maxWidth="sm" sx={{ py: "80px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    "& > img": {
                      maxHeight: "100%",
                      width: "auto",
                    },
                  }}
                >
                  {/* <img alt="Auth platform" src={platformIcons[platform]} /> */}
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <LoginFirebase />
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-reset"
                variant="body2"
              >
                Forgot password
              </Link>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/invitation-code"
                variant="body2"
              >
                Have an invitation code?
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
