import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Dialog,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";

const ContactModal = (props) => {
  const { user } = useAuth();

  return (
    <Dialog maxWidth="sm" onClose={props.toggleDialog} open={props.showDialog}>
      <Box sx={{ p: 3 }}>
        <Typography align="center" color="textPrimary" variant="h4">
          Send us a message
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mt: 3,
            p: 3,
          }}
        >
          <Formik
            initialValues={{
              subject: "",
              text: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              subject: Yup.string()
                .max(200)
                .required("Please add a subject to this email."),
              text: Yup.string()
                .max(2000)
                .required("Please add a body to your email."),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const options = {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                  },
                  body: JSON.stringify({
                    email: user.email,
                    text: values.text,
                    subject: values.subject,
                  }),
                };

                const response = await fetch(
                  process.env.REACT_APP_SEND_CONTACT_EMAIL_URL,
                  options
                ).then((res) => {
                  return res.json();
                });

                if (response.message === "FUNCTION FINISHED.") {
                  toast.success("Your email has been sent.");

                  setTimeout(() => {
                    // wait a few seconds then fade in a message telling the user they can invite people to their group by pressing the button below.
                    setSubmitting(false);
                    props.toggleDialog();
                  }, 2000);
                } else {
                  toast.error("Something went wrong. Please try again later.");
                }
              } catch (err) {
                console.error("CAUGHT ERROR:", err);

                setStatus({ success: false });
                setErrors({
                  submit: "Something went wrong, please try again later.",
                });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit} {...props}>
                <TextField
                  error={Boolean(touched.subject && errors.subject)}
                  fullWidth
                  helperText={touched.subject && errors.subject}
                  label="Subject"
                  margin="normal"
                  name="subject"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.subject}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.text && errors.text)}
                  fullWidth
                  helperText={touched.text && errors.text}
                  label="Your message"
                  margin="normal"
                  multiline
                  name="text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  rows={8}
                  type="text"
                  value={values.text}
                  variant="outlined"
                />
                {isSubmitting && (
                  <LinearProgress style={{ marginBottom: 0, marginTop: 20 }} />
                )}
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    mt: 2,
                  }}
                >
                  <Button
                    color="primary"
                    fullWidth
                    style={{ marginRight: 10 }}
                    onClick={props.toggleDialog}
                    variant="contained"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    fullWidth
                    style={{ marginLeft: 10 }}
                    type="submit"
                    variant="contained"
                  >
                    Send
                  </Button>
                </Box>

                <Box sx={{ mt: 2 }}>
                  {errors.submit && (
                    <Alert severity="error">
                      <div>{errors.submit}</div>
                    </Alert>
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ContactModal;
